import React from "react"
import { useTranslation } from "react-i18next"
import Logo from "src/static/assets/images/favicon.ico"
import i18next from "i18next"
import { Link } from "react-scroll"
import { Link as ReachLink } from "@reach/router"
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap"

const Navbar2 = () => {
  const { t } = useTranslation()
  return (
    <>
      <Navbar
        variant="light"
        expand="lg"
        id="site-navbar"
        fixed="top"
        bg="light"
        collapseOnSelect
      >
        <ReachLink to="/">
          <Navbar.Brand as="span">
            <img src={Logo} width="50" height="50" id="iq-home" alt="null" />{" "}
            QUEUE Technology
          </Navbar.Brand>
        </ReachLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              href="https://admin.qpos.me"
              target="_blank"
              rel="noreferrer"
            >
              Admin
            </Nav.Link>
            <Nav.Link className="dropdown-item" collapseOnSelect>
              <ReachLink to="/help/" style={{ color: "gray" }}>
                {t("nav-2")}
              </ReachLink>
            </Nav.Link>
            <NavDropdown title={t("nav-1")}>
              <NavDropdown.Item className="dropdown-item" collapseOnSelect>
                <ReachLink to="/hardware/" style={{ color: "gray" }}>
                  {t("nav-3")}
                </ReachLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                href="https://forms.gle/vxdr2kM85HGWk5Dw5"
                target="_blank"
                style={{ color: "gray" }}
              >
                {t("nav-4")}
              </NavDropdown.Item>
              <NavDropdown.Item className="dropdown-item" collapseOnSelect>
                <ReachLink to="/service/" style={{ color: "gray" }}>
                  {t("nav-5")}
                </ReachLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="dropdown-item" collapseOnSelect>
              <ReachLink to="/" style={{ color: "gray" }}>
                {t("home")}
              </ReachLink>
            </Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown
              title={t("language-nav")}
              id="langauge-dropdown"
              collapseOnSelect
            >
              <NavDropdown.Item
                className="dropdown-item"
                onClick={() => i18next.changeLanguage("zh-TW")}
                collapseOnSelect
              >
                {t("button-tw")}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                onClick={() => i18next.changeLanguage("en")}
                collapseOnSelect
              >
                {t("button-en")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Navbar2
